/**
 * This js file will be emended into all front end website pages
 */
$(document).ready(function() {
    "use strict";

    /**
     * Tooltip
     */
    $('[data-toggle="tooltip"]').tooltip();

    //current location highlight on navbar
    $("a[href='" + window.location.href + "'].nav-link").addClass('active').css({ 'border-bottom': '1px solid #00c2d6' }, { 'font-weight': 'bolder' });

    //card shadow on mouse enter
    $('div.card,.waterDrop-shape,.btn').mouseenter(function() {
        $(this).addClass('shadow-lg')
    });

    //remove card shadow on mouse leave
    $('div.card,.waterDrop-shape,.btn').mouseleave(function() {
        $(this).removeClass('shadow-lg')
    });

    /**
     * smooth scrolling to all frontend
     * full compatible across all browser solution - better than css property => scroll-behavior: smooth;
     * prevent hash
     */
    $("a").on('click', function(event) {

        // Make sure this.hash has a value before overriding default behavior
        // Also ensure this page contain that id

        if (this.hash !== "" && $(this.hash).length > 0) {

            // Prevent default anchor click behavior
            event.preventDefault();

            // Store hash
            var hash = this.hash;

            // Using jQuery's animate() method to add smooth page scroll
            //  $(selector).animate({params},speed,callback);
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top - 100
            }, 1000, function() {

                // Below line add hash (#) to URL when done scrolling (default click behavior)
                // window.location.hash = hash;
            });

        } // End if

    });

    /**
     * logout
     */
    $('#logout').on('click',function(){
        event.preventDefault();
        document.getElementById('logout-form').submit();
    })
})
